import ContentLoader from 'react-content-loader';

export const RecoveryCodeLoader = () => (
  <ContentLoader
    speed={2}
    width={200}
    height={20}
    viewBox="0 0 200 20"
    backgroundColor="#f3f3f3"
    foregroundColor="#d4d2d2"
  >
    <rect x="0" y="0" rx="3" ry="3" width="180" height="20" />
  </ContentLoader>
);
