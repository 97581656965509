import { NavLink } from 'react-router-dom';

export type NavItemProps = {
  name: string;
  link: string;
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
  testId: string;
  selected?: string;
};

const NavItem = ({ name, link, icon, testId, selected }: NavItemProps) => {
  const Icon = icon;
  return (
    <>
      {
        link !== "" ?
        <NavLink to={link} data-testid={testId}>
          {({ isActive }) => (
            <div
              className={`group relative flex items-center gap-2.5 fill-gray-dark p-2 text-sm font-inter tracking-[-0.154px] rounded-lg group ${
                isActive && selected !== 'Notifications'
                  ? 'bg-primary border border-primary-dark text-white shadow-primary-btn'
                  : 'text-black hover:bg-light-green hover:text-primary'
              }`}
            >
              <p className={`${ isActive && selected !== 'Notifications' ? 'text-white': 'text-icon group-hover:text-primary'}`}>
                <Icon className="h-6 shrink-0" />
              </p>
              <span>{name}</span>
            </div>
          )}
        </NavLink> : 
        <div
          className={`group relative flex items-center gap-2.5 fill-gray-dark p-2 text-sm font-inter tracking-[-0.154px] rounded-lg group ${
            selected === name
              ? 'bg-primary border border-primary-dark text-white shadow-primary-btn'
              : 'text-black hover:bg-light-green hover:text-primary'
          }`}
        >
          <p className={`${ selected === name ? 'text-white': 'text-icon group-hover:text-primary'}`}>
          <Icon className="h-6 shrink-0" />
          </p>
          <span>{name}</span>
        </div>
      }
    </>
  );
};

export default NavItem;
