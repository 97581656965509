import { AdjustmentsIcon, BellIcon } from '@heroicons/react/outline';
import { ScrollArea, Tabs, Tab } from '@mantine/core';
import { useState } from 'react';
import { UnbankNotification } from '../types/notifications';
import TimeAgo from 'react-timeago';
import { useNavigate } from 'react-router-dom';
import { NotificationIcon } from './NotificationIcon';

interface NotificationsProps {
  notifications?: UnbankNotification[];
}

export const Notifications = ({ notifications }: NotificationsProps) => {
  const navigate = useNavigate();
  const categories = [
    "All", "Transactions", "Price Alerts", "Updates"
  ]
  const [currentView, setCurrentView] = useState(0);

  return (
    <>
      <nav  className="pt-5 pb-6">
        <ul className="flex items-center gap-x-2">
          {
            categories.map((category, index) => {
              return ( 
              <li key={`categories-${index}`}>
                <button className={`py-2 px-3 font-semibold rounded-md ${currentView === index ? 'text-primary font-medium bg-light-green': 'text-gray-600 bg-transparent'}`} onClick={() => setCurrentView(index)}>{category}</button>
              </li>
              );
            })
          }
        </ul>
      </nav>

      <section>
        {notifications?.length ? (
          <ScrollArea style={{ height: 250 }}>
            {' '}
            {notifications.map((notification, index) => (
              <div key={`notification-${index}`}>
                <article
                  className={`py-4 w-full grid grid-cols-8  ${
                    index !== notifications.length - 1
                      ? ' border-b border-gray-dark'
                      : ''
                  }`}
                  key={index}
                >
                  <div className="bg-primary h-9 w-9 flex justify-center rounded">
                    <NotificationIcon notificationType={notification.type} />
                  </div>
                  <div className="col-span-7">
                    <h4 className="text-sm font-semibold ">
                      {notification.data.title}
                    </h4>
                    <p className="text-xs w-4/5">{notification.data.message}</p>
                    <p className="text-xs font-semibold text-gray-secondaryDark">
                      <TimeAgo date={new Date(notification.created_at)} />
                    </p>
                  </div>
                </article>
              </div>
            ))}
          </ScrollArea>
        ) : (
          <div className="flex flex-col items-center justify-center h-64">
            <BellIcon className="h-12 w-12 text-gray-dark" />
            <h4 className="font-semibold text-gray-dark">
              No new notifications
            </h4>
            <p className="text-sm text-gray-darker">
              {' '}
              Hooray! You're all caught up!
            </p>
          </div>
        )}
      </section>
    </>
  );
};
