import { MenuIcon } from '@heroicons/react/solid';
import { User } from 'common/types';

type HeaderProps = {
  user: User;
  onOpenDrawer: () => void;
};

const Header = ({ user, onOpenDrawer }: HeaderProps) => {
  return (
    <header className="mb-2 font-inter">
      <h2 className="flex gap-2 text-sm">
        <MenuIcon className="h-8 md:hidden" onClick={onOpenDrawer} />
        Hi <span className="font-bold">{user.firstname}</span>
      </h2>
    </header>
  );
};

export default Header;
