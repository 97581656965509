import { Stepper, StepperProps } from '@mantine/core';
import React from 'react';

export const StyledStepper: React.FC<StepperProps> = ({
  children,
  classNames,
  ...props
}) => {
  return (
    <Stepper
      classNames={{
        root: 'w-full h-full',
        steps: 'lg:px-24 mx-auto max-w-4xl invisible',
        step: 'relative w-[70px] ',
        stepIcon:
          'text-primary absolute left-1/2 -translate-x-1/2 bg-white border-primary',
        stepCompletedIcon: 'bg-primary rounded-full',
        stepBody: '',
        stepLabel: 'absolute left-0 right-0 top-[110%] text-sm text-center',
        content: '',
        separatorActive: '!bg-primary',
        separator: '-mx-[35px] h-[10px] bg-white',
        ...classNames,
      }}
      {...props}
    >
      {children}
    </Stepper>
  );
};
