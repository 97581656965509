import React from 'react';
import ReactDOM from 'react-dom';
import { router } from 'router';
import { RootProvider } from 'providers';
import { RouterProvider } from 'react-router-dom';

import 'plugins';
import './index.css';
import 'react-spring-bottom-sheet/dist/style.css';

ReactDOM.render(
  <React.StrictMode>
    <RootProvider>
      <RouterProvider router={router} />
    </RootProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
