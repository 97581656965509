import Button from 'components/Button';
import PasswordField from 'components/PasswordField';
import { useAuth } from 'modules/auth';
import { useLogin } from 'modules/auth/api/login';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface ConfirmPasswordProps {
  handlePasswordConfirmed: () => void;
}
export const ConfirmPassword = ({
  handlePasswordConfirmed,
}: ConfirmPasswordProps) => {
  // Formik Setup
  const { handleChange, handleSubmit } = useFormik<{ password: string }>({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      login(values.password);
    },
  });

  // Retrieve user phone number
  const { user } = useAuth();

  const { update } = useLogin(handlePasswordConfirmed);

  const login = (password: string) => {
    update({ phone_number: user?.phone_number || '', password });
  };

  return (
    <div className="py-3">
      <p> For your security, please confirm your password to continue. </p>
      <form onSubmit={handleSubmit}>
        <PasswordField
          name="password"
          label="Password"
          onChange={handleChange}
          placeholder="Password"
          className="h-1/2 w-full py-2"
        ></PasswordField>
        <Button type="submit" size="sm">
          Confirm Password
        </Button>
      </form>
    </div>
  );
};
