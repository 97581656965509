import type { AxiosInstance } from "axios";
import { CloseAccountParams, CloseAccountResponse } from "modules/account/types/account"
import { User } from 'common/types';

export default class AccountService {
    axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    fetchAccountData() {
        return this.axios.get<{ data: User }>("/api/account/data");
    }

    fetchWallets() {
        return this.axios.get("/api/account/wallet");
    }

    fetchWidgetUrl(userGuid: string = "") {
        return this.axios.post("/api/account/transaction/ach/get_mx_connect_widget", {
            userGuid
        })
            .then(({ data }) => data)
            .catch(console.log);
    }

    fetchConnectedBankAccounts(userGuid: string) {
        return this.axios.post("/api/account/transaction/ach/listAllConnectedAccountByUser", {
            userGuid
        })
            .then(({ data }) => data)
            .catch(console.log);
    }

    fetchNotificationSettings() {
        return this.axios.get("/api/account/notification/settings");
    }

    updateNotificationSettings() {
        return this.axios.post("/api/account/notification/settings");
    }

    updateAccountInfo() {
        return this.axios.post("/api/account/update");
    }

    removeCashOutMethod(cashoutMethod: string) {
        return this.axios.delete(`api/account/remove/${cashoutMethod}`);
    }

    closeAccount({ reason }: CloseAccountParams) {
        return this.axios.delete<CloseAccountResponse>(`/api/account/removal`,
            {
                data: {
                    reason: reason
                }
            });
    }

}