import { ProgressBar } from 'components/ProgressBar';
import { currencyFormatter } from 'utils/formatters';
import Loader from 'components/Loader';;

type DepositLimitCardProps = {
  title: string;
  limit: number;
  usage: number;
};
export const DepositLimitCard = ({
  title,
  limit,
  usage,
}: DepositLimitCardProps) => {
  const usagePercentage = (usage / limit) * 100;
  return (
    <div className="w-full flex flex-col gap-2 border border-gray-bdr rounded-md bg-gray-50 p-4">
      {
        limit === undefined ?
        <Loader className="w-10 h-10 mx-auto" /> :
        <>   
          <h3 className="font-bold">{title}</h3>
          <small className="text-sm text-gray-600">
            Used {currencyFormatter(usage)} of {currencyFormatter(limit, 0)}
          </small>
          {/* Bar line */}
          <ProgressBar progressPercentage={usagePercentage} /> 
          <small className="text-xs text-right text-gray-600">
            { currencyFormatter(limit - usage) } remaining
          </small>
        </>
      }
    </div>
  );
};
