import {
  GetExchangeRateResponse,
  SourceData,
  UnbankFee,
  MinerFee,
} from 'common/types';

export default class ExchangeRate implements GetExchangeRateResponse {
  id: number;
  from: string;
  to: string;
  rate: string;
  volume: string;
  source_data: SourceData;
  source: string;
  currency_type: string;
  transaction_type: string;
  markup: string | null;
  created_at: Date;
  updated_at: Date;
  exchange: number;
  crypto_amount_before_fee?: string;
  crypto_amount: string;
  exchange_after_tax: number;
  exchange_result: number;
  total_cost: number;
  exchange_rate?: string;
  exchange_result_before_tax: number;
  unbank_fee?: UnbankFee;
  miner_fee?: MinerFee;

  constructor({
    id,
    from,
    to,
    rate,
    volume,
    source_data,
    source,
    currency_type,
    transaction_type,
    markup,
    created_at,
    updated_at,
    exchange,
    crypto_amount_before_fee,
    crypto_amount,
    exchange_after_tax,
    exchange_result,
    total_cost,
    exchange_rate,
    exchange_result_before_tax,
    unbank_fee,
    miner_fee,
  }: GetExchangeRateResponse) {
    this.id = id;
    this.from = from;
    this.to = to;
    this.rate = rate;
    this.volume = volume;
    this.source_data = source_data;
    this.source = source;
    this.currency_type = currency_type;
    this.transaction_type = transaction_type;
    this.markup = markup;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.exchange = exchange;
    this.crypto_amount_before_fee = crypto_amount_before_fee;
    this.crypto_amount = crypto_amount;
    this.exchange_after_tax = exchange_after_tax;
    this.exchange_result = exchange_result;
    this.total_cost = total_cost;
    this.exchange_rate = exchange_rate;
    this.exchange_result_before_tax = exchange_result_before_tax;
    this.unbank_fee = unbank_fee;
    this.miner_fee = miner_fee;
  }

  static createExchangeRateFromResponse(rate: ExchangeRate): ExchangeRate {
    return {
      id: rate.id,
      from: rate.from,
      to: rate.to,
      rate: rate.rate,
      volume: rate.volume,
      source_data: rate.source_data,
      source: rate.source,
      currency_type: rate.currency_type,
      transaction_type: rate.transaction_type,
      markup: rate.markup,
      created_at: rate.created_at,
      updated_at: rate.updated_at,
      exchange: rate.exchange,
      crypto_amount_before_fee: rate.crypto_amount_before_fee,
      crypto_amount: rate.crypto_amount,
      exchange_after_tax: rate.exchange_after_tax,
      exchange_result: rate.exchange_result,
      total_cost: rate.total_cost,
      exchange_rate: rate.exchange_rate,
      exchange_result_before_tax: rate.exchange_result_before_tax,
      unbank_fee: rate.unbank_fee,
      miner_fee: rate.miner_fee,
    }
  }
}
