import { useEffect, useState } from 'react';
import { TickerCard } from './TickerCard';
import { GetExchangeRateResponse } from 'common/types';
import { LitecoinIcon, BitcoinIcon } from 'components/icons';
import { useExchangeRateService } from 'modules/overview/providers/ExchangeRateProvider';
import ExchangeRate from 'modules/overview/models/ExchangeRates';

const TickerCardList = () => {
  const [loading, setIsLoading] = useState(false);
  const [ltcToUsd, setLtcToUsd] = useState<GetExchangeRateResponse>();
  const [btcToUsd, setBtcToUsd] = useState<GetExchangeRateResponse>();
  const exchangeRateService = useExchangeRateService();

  const fetchCoinData = async () => {
    setIsLoading(true);  

    let btc = await exchangeRateService.fetchExchangeRate({ from: 'BTC', to: 'USD' });
    let ltc = await exchangeRateService.fetchExchangeRate({ from: 'LTC', to: 'USD' });

    setBtcToUsd(ExchangeRate.createExchangeRateFromResponse(btc));
    setLtcToUsd(ExchangeRate.createExchangeRateFromResponse(ltc));
    setIsLoading(false);
  };

  useEffect(() => {
    let interval: NodeJS.Timer | null = null;
    const INTERVAL_IN_MS = 30000; // 30 secs

    if(ltcToUsd !== undefined && btcToUsd !== undefined) {
      interval = setInterval(() => {
        fetchCoinData();
      }, INTERVAL_IN_MS);
    } else {
      fetchCoinData();  
    }

    return () => {
      if(interval !== null) {
        clearInterval(interval);
      }
    }
  }, []);

  return (
    <div>
      <h2 className="mb-6 flex-grow border-b border-gray-bdr pb-3 text-2xl font-bold   ">
        Ticker Update
      </h2>
      <div className="flex flex-col gap-3">
        <TickerCard
          name="Bitcoin"
          symbol={btcToUsd?.from || ''}
          loading={loading}
          percentage={Number(btcToUsd?.source_data.percent_change_24)}
          amount={Number(btcToUsd?.rate)}
        >
          <BitcoinIcon />
        </TickerCard>
        <TickerCard
          name="Litecoin"
          symbol={ltcToUsd?.from || ''}
          loading={loading}
          percentage={Number(ltcToUsd?.source_data.percent_change_24)}
          amount={Number(ltcToUsd?.rate)}
        >
          <LitecoinIcon />
        </TickerCard>
        ;
      </div>
    </div>
  );
};

export default TickerCardList;
