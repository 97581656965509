import { Transaction } from 'modules/transactions';

import { useSortableData } from 'common/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useGetCombinedTransactions } from '../../api/getTransactions';
import { useModals } from '@mantine/modals';
import { Invoice } from 'modules/sell';
import { Link } from 'react-router-dom';
import { InvoiceCard } from './InvoiceCard';
import { InvoiceModal } from '../InvoiceModal';
import { TransactionCard } from './TransactionCard';
import { SellTransactionModal } from '../SellTransactionModal';
import { WebRecieptModal } from '../WebReceiptModal';
import { GreendotBuyModal } from '../GreendotBuyModal';
import { ServiceProvider } from 'common/types';
import { TritonBuyModal } from '../TritonBuyModal';
import { KyckStatus } from 'modules/sell/types/kyckStatuses';
import { filterTransactions } from 'modules/transactions/utils';
import { TransactionFilterConfig } from 'modules/transactions/types/TransactionFilterConfig';

type TransactionListProps = {
  showRecent?: boolean;
  filters?: TransactionFilterConfig;
};

export const TransactionList = ({ filters }: TransactionListProps) => {
  const { data } = useGetCombinedTransactions();
  const [combinedTransactions, setCombinedTransactions] = useState<
    (Transaction | Invoice)[]
  >([]);
  const modals = useModals();

  useEffect(() => {
    if (data) {
      setCombinedTransactions(data.data);
    } else {
      console.log('No Data');
    }
  }, [data, combinedTransactions]);

  /** Filters out transactions based on model
   * @param transactionModel The respective model, whether a 'Transaction' or 'Invoice'
   */
  const getTransactionsByModel = (transactionModel: string) =>
    combinedTransactions.filter((transaction: Transaction | Invoice) => {
      return transaction.model_type === transactionModel;
    });

  /**  Enforces Invoice Array type to result */
  const getInvoices = () => {
    return getTransactionsByModel('Invoice') as Invoice[];
  };

  const completedInvoices = getInvoices().filter(
    (invoice) => invoice.status === 'Completed'
  );

  const pendingInvoices = getInvoices().filter(
    (invoice) => invoice.status !== 'Completed'
  );

  /**  Enforces Transaction Array type to result */
  const getTransactions = () => {
    return getTransactionsByModel('Transaction') as Transaction[];
  };

  const pendingTransactions = getTransactions().filter(
    (transaction) => transaction.status !== 'Completed'
  );
  const completedTransactions = getTransactions().filter(
    (transaction) => transaction.status === 'Completed'
  );

  const actionableTransactions = getTransactions().filter((transaction) =>
    ['Pickup Ready', 'Pending Deposit'].includes(transaction.status)
  );

  const remainingTransactions = getTransactions().filter(
    (transaction) =>
      !['Pickup Ready', 'Pending Deposit'].includes(transaction.status)
  );

  const { requestSort, sortConfig, sortedItems } = useSortableData(
    getTransactions(),
    { sortBy: 'transfer_date_utc', isAscending: false }
  );

  const filteredTransactions = useMemo(
    () => (filters ? filterTransactions(sortedItems, filters) : sortedItems),
    [filters, sortedItems]
  );

  const handleSort = (field?: string) => {
    if (field) {
      requestSort(field);
    }
  };

  const handleSelectInvoice = (invoice: Invoice) => {
    modals.openModal({
      size: 'xl',
      children: <InvoiceModal invoice={invoice} />,
    });
  };

  const handleSelectTransaction = (transaction: Transaction) => {
    if (
      transaction.transaction_type === 'sell' &&
      transaction.status !== KyckStatus.EXPIRED
    ) {
      modals.openModal({
        size: 'md',
        children: <SellTransactionModal transaction={transaction} />,
      });
    } else if (transaction.status === 'Completed') {
      console.log('Selecting completed transaction');
      modals.openModal({
        size: 'xl',
        children: <WebRecieptModal transaction={transaction} />,
      });
    } else if (
      // Display greendot modal if transaction is greendot
      transaction.transaction_type === 'buy' &&
      transaction.service_provider === 'greendot'
    ) {
      modals.openModal({
        size: 'xl',
        children: <GreendotBuyModal transaction={transaction} />,
      });
    } else if (
      transaction.transaction_type === 'buy' &&
      transaction.service_provider === ServiceProvider.TRITON
    ) {
      modals.openModal({
        size: 'xl',
        children: <TritonBuyModal transaction={transaction} />,
      });
    }
  };

  if (combinedTransactions.length === 0) {
    return (
      <div className="my-auto flex flex-col text-center">
        You don't have any transactions yet. Make one by
        <div>
          <Link
            className="text-lg uppercase text-primary underline"
            to="/dashboard/buy"
          >
            buying
          </Link>{' '}
          or{' '}
          <Link
            className="text-lg uppercase text-primary underline"
            to="/dashboard/sell"
          >
            selling
          </Link>{' '}
          some crypto!
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div>
          {getInvoices().map((invoice) => (
            <InvoiceCard
              key={invoice.order_id}
              invoice={invoice}
              onSelectInvoice={handleSelectInvoice}
            />
          ))}
          <div>
            {actionableTransactions.length !== 0 ? (
              <h3 className="mb-1 w-full border-b border-gray-bdr pb-3 text-sm text-gray-500">
                Needs attention
              </h3>
            ) : null}
            {actionableTransactions.map((transaction) => (
              <TransactionCard
                key={transaction.order_id}
                transaction={transaction}
                onSelectTransaction={handleSelectTransaction}
              />
            ))}

            {remainingTransactions.length !== 0 ? (
              <h3 className="mt-7 mb-1 w-full border-b border-gray-bdr pb-3 text-sm text-gray-500">
                Recent
              </h3>
            ) : null}
            {remainingTransactions.slice(0, 5).map((transaction) => (
              <TransactionCard
                key={transaction.order_id}
                transaction={transaction}
                onSelectTransaction={handleSelectTransaction}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
};
