import Button from 'components/Button';
import checkCircle from 'assets/icons/check_circle.svg';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../api/login';
import storage from 'utils/storage';
import { LoginApiResponse } from '../types/login';
import { useAuth } from '../providers';

type ConfirmationSectionProps = {
  phoneNumber: string;
  password: string;
};

const ConfirmationSection = ({
  phoneNumber,
  password,
}: ConfirmationSectionProps) => {
  // const { setUser } = useAuth();
  const handleSuccess = (data: LoginApiResponse) => {
    // setUser(data.user);
    storage.setToken(data.unbank.token);
    navigate('/dashboard/overview');
  };
  const login = () => {
    update({ phone_number: phoneNumber, password });
  };

  const navigate = useNavigate();
  const { isLoading, update } = useLogin(handleSuccess);

  return (
    <div className="flex h-full flex-col items-center justify-center space-y-8">
      <img src={checkCircle} className="h-[80px] w-auto" alt="" />
      <p className="text-lg">Your email has been verified!</p>

      <Button loading={isLoading} onClick={login}>
        Continue
      </Button>
    </div>
  );
};

export default ConfirmationSection;
