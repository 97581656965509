import { Group, Text } from '@mantine/core';
import React, { forwardRef } from 'react';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  name: string;
  value: string;
  label: string;
  dialCode: string;
}

export const CountrySelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ name, value, label, dialCode, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="md">
            {name} ({dialCode})
          </Text>
        </div>
      </Group>
    </div>
  )
);
