import React, { useContext, useState } from 'react';
import TwoFactorService from 'modules/two-factor/services/TwoFactorService';
import { axios } from 'lib/axios';

/**
 * Context for managing two-factor authentication state and services.
 */
interface TwoFactorContextType {
  twoFactorService: TwoFactorService;
  isTwoFactorAuthenticated: boolean;
  setIsTwoFactorAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  isTwoFactorValid: boolean;
  setIsTwoFactorValid: React.Dispatch<React.SetStateAction<boolean>>;
}

// Default context value
const twoFactorService = new TwoFactorService(axios);
const defaultTwoFactorContext: TwoFactorContextType = {
  twoFactorService,
  isTwoFactorAuthenticated: false,
  setIsTwoFactorAuthenticated: () => {},
  isTwoFactorValid: false,
  setIsTwoFactorValid: () => {},
};

const TwoFactorContext = React.createContext<TwoFactorContextType>(
  defaultTwoFactorContext
);

export const useTwoFactorService = () => useContext(TwoFactorContext);

export const TwoFactorProvider: React.FC = ({ children }) => {
  const [isTwoFactorAuthenticated, setIsTwoFactorAuthenticated] =
    useState<boolean>(false);
  const [isTwoFactorValid, setIsTwoFactorValid] = useState<boolean>(false);

  return (
    <TwoFactorContext.Provider
      value={{
        twoFactorService,
        isTwoFactorAuthenticated,
        setIsTwoFactorAuthenticated,
        isTwoFactorValid,
        setIsTwoFactorValid,
      }}
    >
      {children}
    </TwoFactorContext.Provider>
  );
};
