import { Navigate } from 'react-router-dom';
import notification from 'utils/notification';
import { useAuth } from '../providers';
import { useTwoFactorService } from 'modules/two-factor/providers';

type GuardedRouteProps = {
  component: JSX.Element;
};

/** Route to restrict access from authentication */
const GuardedRoute = ({ component }: GuardedRouteProps) => {
  const { isAuthenticated } = useAuth();
  const { isTwoFactorAuthenticated, isTwoFactorValid } = useTwoFactorService();

  if (isAuthenticated && !isTwoFactorAuthenticated) {
    return component;
  } else if (isAuthenticated && isTwoFactorAuthenticated) {
    if (isTwoFactorValid) {
      return component;
    } else {
      notification.notify(
        'Please complete two factor verification before proceeding',
        5000,
        '2fa-verify-id'
      );
      return <Navigate to="/auth/2fa-verify" />;
    }
  } else {
    <Navigate to="/auth/login" />;
  }

  return <>{isAuthenticated ? component : <Navigate to="/auth/login" />}</>;
};

export default GuardedRoute;
