import { PasswordInput, PasswordInputProps } from '@mantine/core';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface TempProps {
  isAuth?: boolean;
}

type PasswordFieldProps = TempProps &
  PasswordInputProps &
  React.RefAttributes<HTMLInputElement>;

const PasswordField = ({ ...props }: PasswordFieldProps) => {
  return (
    <PasswordInput
      styles={
        props.isAuth
          ? { label: { fontSize: '12px' }, input: { fontSize: '24px' } }
          : {
              input: { fontSize: '18px' },
              label: { fontSize: '14px' },
            }
      }
      color="gray-dark"
      size="lg"
      variant={props.isAuth ? 'default' : 'filled'}
      visibilityToggleIcon={({ reveal, size }) =>
        reveal ? <VisibilityOff /> : <Visibility />
      }
      {...props}
    />
  );
};

export default PasswordField;
