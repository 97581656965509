import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { CheckCircleIcon, RefreshIcon } from '@heroicons/react/outline';

interface StyledOrderStatusProps {
  orderStatus: string;
}
export const StyledOrderStatus = ({ orderStatus }: StyledOrderStatusProps) => {
  const baseStyles =
    'flex px-2 py-0.5 w-fit gap-1 rounded-full justify-center items-center my-2 text-xs';
  const iconStyles = 'h-4 text-white';
  const completedIconStyles = 'h-4 text-primaryDark';
  switch (orderStatus) {
    case 'Expired':
      return (
        <article className={`${baseStyles} bg-red-500 text-white`}>
          <div>
            <ExclamationCircleIcon className={`${iconStyles}`} />
          </div>
          <p className="font-semibold">{orderStatus}</p>
        </article>
      );
    case 'Rejected':
      return (
        <article className={`${baseStyles} bg-red-500 text-white`}>
          <div>
            <ExclamationCircleIcon className={`${iconStyles}`} />
          </div>
          <p className="font-semibold">{orderStatus}</p>
        </article>
      );
    case 'New':
      return (
        <article className={`${baseStyles} bg-success-100 text-success-700`}>
          <div>
            <ExclamationCircleIcon className={`${completedIconStyles}`} />
          </div>
          <p className="font-semibold">{orderStatus}</p>
        </article>
      );
    case 'Success':
      return (
        <article className={`${baseStyles} bg-success-100 text-success-700`}>
          <div>
            <CheckCircleIcon className={`${completedIconStyles}`} />
          </div>
          <p className="font-semibold">{orderStatus}</p>
        </article>
      );
    case 'Completed':
      return (
        <article className={`${baseStyles} bg-success-100 text-success-700`}>
          <div>
            <CheckCircleIcon className={`${completedIconStyles}`} />
          </div>
          <p className="font-semibold">{orderStatus}</p>
        </article>
      );
    case 'Pending':
      return (
        <article className={`${baseStyles} bg-blue-500 text-white`}>
          <div>
            <RefreshIcon className={`${iconStyles}`} />
          </div>
          <p className="font-semibold">{orderStatus}</p>
        </article>
      );
    default:
      return (
        <article className={`${baseStyles} bg-blue-500 text-white`}>
          <div>
            <ExclamationCircleIcon className={`${iconStyles}`} />
          </div>
          <p className="font-semibold">{orderStatus}</p>
        </article>
      );
  }
};
