export const BitcoinIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_388_8882)">
        <path
          d="M11.8205 7.45146C11.019 10.6658 7.76309 12.6219 4.54803 11.8204C1.33432 11.0191 -0.622074 7.76327 0.179711 4.54921C0.980793 1.33454 4.23674 -0.621772 7.4508 0.179571C10.6656 0.980914 12.6219 4.23705 11.8204 7.45152L11.8204 7.45146H11.8205Z"
          fill="#F7931A"
        />
        <path
          d="M8.64616 5.14517C8.7656 4.3467 8.15763 3.91749 7.32628 3.63117L7.59598 2.54957L6.9375 2.38551L6.67496 3.43863C6.50185 3.39546 6.32408 3.35478 6.14739 3.31446L6.41184 2.25439L5.75377 2.09033L5.48393 3.17158C5.34068 3.13896 5.19998 3.10673 5.06347 3.07277L5.06423 3.06937L4.15618 2.84264L3.98102 3.54585C3.98102 3.54585 4.46955 3.65783 4.45926 3.66472C4.7259 3.73126 4.77412 3.90775 4.76612 4.04765L4.45891 5.27984C4.47727 5.2845 4.50108 5.29125 4.52736 5.30181C4.5054 5.29635 4.48202 5.2904 4.45776 5.28459L4.02716 7.01072C3.99457 7.09172 3.91186 7.21328 3.72543 7.16712C3.73203 7.17668 3.24684 7.0477 3.24684 7.0477L2.91992 7.80135L3.77682 8.01495C3.93623 8.05492 4.09245 8.09674 4.24629 8.13607L3.97381 9.23007L4.63152 9.39413L4.90136 8.31174C5.08104 8.36051 5.25541 8.4055 5.42612 8.44791L5.15718 9.52519L5.81569 9.68925L6.08814 8.5973C7.21098 8.80978 8.05527 8.72411 8.41064 7.70862C8.69699 6.89103 8.39638 6.41944 7.80566 6.11192C8.23591 6.0127 8.55999 5.72975 8.6464 5.14526L8.64619 5.14511L8.64616 5.14517ZM7.1417 7.25457C6.9382 8.07216 5.56148 7.6302 5.11512 7.51937L5.47671 6.07001C5.92304 6.18142 7.35438 6.40191 7.14173 7.25457H7.1417ZM7.34535 5.13332C7.15971 5.877 6.01385 5.49918 5.64214 5.40653L5.96997 4.09205C6.34168 4.18469 7.53872 4.35761 7.3454 5.13332H7.34535Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_388_8882">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
