import React, { useState, useEffect } from 'react';
import { TextInputProps, Select, TextInput } from '@mantine/core';
import {
  AsYouType,
  CountryCode,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';

import {
  getCountryDialCode,
  countriesList,
} from 'modules/auth/utils/countries';
import { CountrySelectItem } from './CountrySelectItem';

interface PhoneNumberFieldProps
  extends Omit<TextInputProps, 'value' | 'onChange'> {
  value: string;
  name: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  error?: string | undefined;
  dataTestId?: string;
}

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
  value,
  name,
  onChange,
  onBlur,
  error,
  dataTestId,
  ...props
}) => {
  const [selectedCountry, setSelectedCountry] = useState<string | null>('US');
  const [isFocused, setIsFocused] = useState<boolean>(false);

  /**
   * Effect to update the selected country based on the provided phone number value.
   */
  useEffect(() => {
    if (value) {
      const parsedPhoneNumber = parsePhoneNumberFromString(
        value,
        selectedCountry as CountryCode
      );
      if (parsedPhoneNumber) {
        const countryCode = parsedPhoneNumber.country;
        if (countryCode !== selectedCountry) {
          setSelectedCountry(countryCode as CountryCode);
        }
      }
    }
  }, [value]);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    onBlur();
  };

  /**
   * Update the phone input based on the selected country.
   *
   * @param {string} event - The selected country code.
   */
  const handleCountryChange = (event: string) => {
    const countryCode = event;
    setSelectedCountry(countryCode);
    const dialCode = getCountryDialCode(countryCode);
    if (dialCode) {
      const updatedPhoneNumber = new AsYouType(
        countryCode as CountryCode
      ).input(`${dialCode} `);
      onChange(updatedPhoneNumber);
    }
  };

  /**
   * Handle changes to the phone number input.
   * Format and update the phone number based on the selected country.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event from the phone number input.
   */
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = new AsYouType(
      (selectedCountry as CountryCode) || 'US'
    ).input(e.target.value);
    onChange(phoneNumber);
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    if (parsedPhoneNumber) {
      const countryCode = parsedPhoneNumber.country;
      if (countryCode && countryCode !== selectedCountry) {
        setSelectedCountry(countryCode);
      }
    }
  };

  return (
    <div>
      <label className="text-xs" htmlFor={name}>
        {props.label}
      </label>
      <div
        className={`flex items-center rounded border border-gray-300 ${
          error ? 'border-red-500' : isFocused ? 'border-primary' : ''
        }`}
        data-testid="phone_input_wrapper"
      >
        <Select
          name="country_select"
          data-testid="phone_country_select"
          styles={{
            input: { border: 'none', width: '400px' },

            wrapper: { width: '40px' },
            rightSection: { left: '30px', zIndex: '100' },
          }}
          className=""
          value={selectedCountry || ''}
          onChange={handleCountryChange}
          searchable
          data={countriesList.map((country) => ({
            value: country.code,
            label: `${country.code}`,
            name: country.name,
            dialCode: country.dialCode,
          }))}
          itemComponent={CountrySelectItem}
          placeholder={
            countriesList.find((country) => country.code === selectedCountry)
              ?.dialCode
          }
        />
        <TextInput
          name={name}
          className="w-full px-5"
          size="md"
          styles={{
            input: { border: 'none', fontSize: '16px' },
          }}
          placeholder={props.placeholder}
          value={value}
          onChange={handlePhoneNumberChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          data-testid={dataTestId ? dataTestId : 'phone_number_input'}
        />
      </div>
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
};

export default PhoneNumberField;
