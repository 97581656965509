export interface NotificationResponse {
    current_page: number;
    data: UnbankNotification[];

}
export interface UnbankNotification {
    id: string;
    type: string;
    notifiable_type: string;
    notifiable_id: string,
    data: NotificationData;
    read_at?: string;
    created_at: string;
    updated_at: string;
}
interface NotificationData {
    title?: string;
    message: string;
    status: string;
}
export interface NotificationReadResponse {
    read_count: number;
    message: string;
    status: number
}
export enum NotificationType {
    MESSAGE_NOTIFICATION = 'App\\Notifications\\MessageNotification',
    VERIFY_EMAIL_NOTIFICATION = 'App\\Notifications\\VerifyEmail',
    PRODUCT_ANNOUNCEMENT = 'ProductAnnouncement',
    SECURITY_ALERT = 'SecurityAlert',
    CRYPTO_ALERT = 'CryptoUpdated',
}


export interface NotificationSettings {
    data: NotificationSetting[]
}

export enum NotificationTypes {
    PRODUCT_ANNOUNCEMENT = 'product_announcemnet',
    BTC_PRICE_ALERT = 'btc_price_alert',
    LTC_PRICE_ALERT = 'ltc_price_alert',
    SECURITY_ALERT = 'security_alert'
}

export enum NotificationCategory {
    ENABLE_EMAIL = 'enable_email',
    ENABLE_WEB = 'enable_web',
    ENABLE_FCM = 'enable_fcm'
}

export interface NotificationSetting {
    name: string;
    title: string;
    event: string;
    enable_email: boolean;
    enable_web: boolean;
    enable_fcm: boolean;
    enable_sms: boolean;
}