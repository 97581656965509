import React,  { useContext } from 'react';
import ExchangeRateService from '../services/ExchangeRateService';
import { axios } from 'lib/axios';

const exchangeRateServiceInstance = new ExchangeRateService(axios);

const ExchangeRateContext = React.createContext<ExchangeRateService>(exchangeRateServiceInstance);

// Export the hook
export const useExchangeRateService = () => useContext(ExchangeRateContext);

export const AccountProvider: React.FC= ({ children }) => {
    return <ExchangeRateContext.Provider value={exchangeRateServiceInstance}> { children }</ExchangeRateContext.Provider>;
};
