import { useState, useEffect } from 'react';
import logo from 'assets/imgs/unbank-logo.svg';
import Transitions from 'components/Transitions';
import { CloseIcon } from 'components/icons';
import {
  DashboardIcon,
  BuyIcon,
  SellIcon,
  TransactionIcon,
  LocationIcon,
  NotificationIcon,
  SupportIcon,
  SettingsIcon,
  LogoutIcon,
} from 'components/icons';
import NavItem, { NavItemProps } from './NavItem';
import { useAuth, useLogout } from 'modules/auth';
import {
  getNotifications,
  markAsRead,
  Notifications,
  UnbankNotification,
} from 'modules/notifications';
import { POLLING_INTERVAL_IN_MS } from 'modules/buy/util/constants';


type AsideProps = {
  isDrawerOpen: boolean;
  onLogout: (data: unknown) => void;
};

const items: NavItemProps[] = [
  {
    name: 'Dashboard',
    link: '/dashboard/overview',
    icon: DashboardIcon,
    testId: 'dashboard-sidebar-home-link',
  },
  {
    name: 'Buy',
    link: '/dashboard/buy',
    icon: BuyIcon,
    testId: 'dashboard-sidebar-buy-link',
  },
  {
    name: 'Sell',
    link: '/dashboard/sell',
    icon: SellIcon,
    testId: 'dashboard-sidebar-sell-link',
  },
  {
    name: 'Locations',
    link: '/dashboard/locations',
    icon: LocationIcon,
    testId: 'dashboard-sidebar-locations-link',
  },
  {
    name: 'Transactions',
    link: '/dashboard/transactions',
    icon: TransactionIcon,
    testId: 'dashboard-sidebar-transaction-link',
  },
  {
    name: 'Settings',
    link: '/dashboard/account/general',
    icon: SettingsIcon,
    testId: 'dashboard-sidebar-settings-link',
  },
  {
    name: 'Notifications',
    link: '',
    icon: NotificationIcon,
    testId: 'dashboard-sidebar-notifications-link',
  },
  {
    name: 'Support',
    link: '/dashboard/help',
    icon: SupportIcon,
    testId: 'dashboard-sidebar-support-link',
  },
];

const Aside = ({ isDrawerOpen, onLogout }: AsideProps) => {
  const { user } = useAuth();
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const openNotifications = () => {
    setOpened(!opened);
    setSelected('Notifications');
  }

  const selectNavItem = (name: string) => {
    if(opened) setOpened(false);
    setSelected(name);
  }

  const [notifications, setNotifications] = useState<UnbankNotification[]>();
  const fetchNotifications = async () => {
    if (user) {
      const fetchedNotifications = await getNotifications(user.email);

      const filteredNotifications = fetchedNotifications.data.filter(
        (notification) => {
          return !notification.read_at;
        }
      );
      setNotifications(filteredNotifications);
    }
  };

  const handleCloseNotifications = async () => {
    // Generate list of notification ids
    if (notifications) {
      const notificationIds = notifications?.map((notification) => {
        return notification.id;
      });

      // Mark notifications as read
      markAsRead(notificationIds);

      // Refresh notifications list
      await fetchNotifications();
    }
    openNotifications();
  };

  useEffect(() => {
    if (user) {
      // Refresh notifications if there are notifications
      if (notifications) {
        const interval = setInterval(
          () => fetchNotifications(),
          POLLING_INTERVAL_IN_MS / 2
        );

        return () => {
          clearInterval(interval);
        };
        // Populate locations on first render
      } else {
        fetchNotifications();
      }
    }
  }, []);

  return (
    <>
      <aside
        className={`fixed top-0 z-50 h-screen w-[234px] shrink-0 bg-gray-bkg py-12 transition-all md:sticky ${
          isDrawerOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'
        }`}
      >
        <header className="flex items-center justify-center space-x-2 text-xl font-semibold text-secondary">
          <img src={logo} className="h-8" alt="Unbank Logo" />
        </header>

        {/* Options */}
        <nav className="mt-2">
          <ul className="mx-4 flex flex-col border-t border-dashed border-gray-bdr pt-1">
            <div className="pt-3 border-b border-dashed border-gray-bdr">
              {items.slice(0, 5).map((item) => (
                <button key={item.name} className="w-full mb-3" onClick={() => selectNavItem(item.name)}>
                  <NavItem key={item.name} {...item} selected={selected}/>
                </button>
              ))}
            </div>

            <div className="py-3 border-b border-dashed border-gray-bdr">
              <button className="w-full mb-3" onClick={() => selectNavItem(items[5].name)}>
                <NavItem key={items[5].name} {...items[5]} selected={selected} />
              </button>

              {/* Notification Link */}
              <button className="w-full" onClick={handleCloseNotifications}>
                <NavItem key={items[6].name} {...items[6]} selected={selected} />
              </button>
            </div>

            <div className="py-3 mb-3 border-b border-dashed border-gray-bdr">
              <button className="w-full" onClick={() => selectNavItem(items[7].name)}>
                <NavItem key={items[7].name} {...items[7]} selected={selected} />
              </button>
            </div>

            <button className="w-full" onClick={onLogout}>
              <NavItem
                name="Logout"
                link=""
                icon={LogoutIcon}
                testId="logoutBtn"
              />
            </button>
          </ul>
        </nav>
      </aside>
      <aside
        className={`fixed top-0 left-0 md:left-[234px] z-[60] md:z-40 h-screen max-w-[520px] bg-white font-inter shrink-0 py-9 px-4 transition-all ${
          opened ? 'translate-x-0' : '-translate-x-[200%]'
        }`}
      >
        <header>
          <p className="text-sm text-gray-700">Hi <span className="font-bold">{user?.firstname}</span> </p>
          <div className="flex items-center justify-between">
            <p className="pt-1 text-xl text-gray-700 font-semibold leading-[140%] tracking-[-0.22px]">Notification</p>
            <button onClick={() => setOpened(false)}>
              <CloseIcon />
            </button>
          </div>
        </header>
        <Notifications notifications={notifications} />
      </aside>

      {/* Backdrop */}
      <Transitions.FadeIn reveal={opened}>
        <div
          onClick={() => setOpened(false)}
          className="fixed top-0 left-[234px] z-50 md:z-30 h-screen w-screen bg-[rgba(0,0,0,0.3)]"
        />
      </Transitions.FadeIn>
    </>
  );
};

export default Aside;
