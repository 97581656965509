import { useState } from 'react';
import { TwoFactorQR } from './TwoFactorQR';
import { ConfirmPassword } from './ConfirmPassword';
import { ConfirmTwoFactor } from './ConfirmTwoFactor';
import Button from 'components/Button';
import ResponsiveModal from 'components/Modal';
import { useTwoFactorService } from 'modules/two-factor/providers';

interface TwoFactorSetupModalProps {
  opened: boolean;
  onClose(): void;
}

/**
 * TwoFactorSetupModal component: A modal dialog to set up Two-Factor Authentication.
 * @param {boolean} opened - Whether the modal is currently opened.
 * @param {Function} onClose - Callback when the modal needs to close.
 */
export const TwoFactorSetupModal = ({
  opened,
  onClose,
  ...props
}: TwoFactorSetupModalProps) => {
  // States for managing the authentication process flow
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false);
  const [shouldProceedTo2FA, setShouldProceedTo2FA] = useState(false);

  // 2FA service hook
  const { twoFactorService } = useTwoFactorService();

  /**
   * Handles the enabling of QR-based 2FA.
   */
  const handleQREnable = async () => {
    setIsPasswordConfirmed(true);
    try {
      await twoFactorService.enableTwoFactorAuth();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ResponsiveModal
      opened={opened}
      title={<h2 className="py-2"> Setup Two Factor Authentication</h2>}
      size="50%"
      onClose={onClose}
      {...props}
    >
      {isPasswordConfirmed ? (
        <div className="px-5 py-3">
          <div>
            {shouldProceedTo2FA ? (
              <ConfirmTwoFactor handleTwoFactorConfirmed={() => onClose()} />
            ) : (
              <div>
                <TwoFactorQR />
                <div className="flex items-center justify-center">
                  <Button
                    type="submit"
                    size="sm"
                    onClick={() => setShouldProceedTo2FA(true)}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <ConfirmPassword handlePasswordConfirmed={() => handleQREnable()} />
      )}
    </ResponsiveModal>
  );
};
