import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { ConfirmPassword } from 'modules/two-factor/components/ConfirmPassword';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import Button from 'components/Button';
import { Textarea } from '@mantine/core';
import { useAccountService } from 'modules/account/providers/AccountProvider';

interface CloseAccountModalProps {
  onClose: () => void;
}
export const CloseAccountModal = ({ onClose }: CloseAccountModalProps) => {
  const [step, setStep] = useState(0);
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const accountService = useAccountService();

  const closeUserAccount = async (reason: string) => {
    setIsRequestLoading(true);

    await accountService.closeAccount({
      reason: reason,
    });

    setIsRequestLoading(false);
  };
  const { handleChange, handleSubmit } = useFormik<{ reason: string }>({
    initialValues: {
      reason: '',
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      // Close Account
      closeUserAccount(values.reason);
    },
  });

  return (
    <div>
      {step === 0 && (
        <div className="flex flex-col items-center px-5">
          <div className="mb-3 inline-block rounded-full bg-light-green p-5">
            <ExclamationCircleIcon className="h-10 text-primary" />
          </div>
          <h2 className="font-lg my-2"> Close Account </h2>
          <p className="text-center">
            Are you sure you want to close your Unbank Account? If you are
            having any difficulties feel free to contact support for assistance
          </p>
          <div className="my-5 flex items-center">
            <button
              onClick={() => onClose()}
              className="btn bg-primary text-white"
            >
              Cancel
            </button>
            <button
              className="btn bg-white text-tw-gray-700 "
              onClick={() => {
                setStep(1);
              }}
            >
              Close Account
            </button>
          </div>
        </div>
      )}
      {step === 1 && (
        <div>
          <h2> Confirm your Password</h2>
          <ConfirmPassword
            handlePasswordConfirmed={() => {
              setStep(2);
            }}
          />
        </div>
      )}
      {step === 2 && (
        <form className="mt-8" onSubmit={handleSubmit}>
          <h2> Close Your Account</h2>
          <p className="mb-3 text-sm text-gray-darker">
            Please state your reason for closing your account below
          </p>
          <Textarea
            name="reason"
            onChange={handleChange}
            minRows={5}
            placeholder="Reason for closing account"
          />
          <Button size="sm" className="my-3" loading={isRequestLoading}>
            Close Account
          </Button>
        </form>
      )}
    </div>
  );
};
