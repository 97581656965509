import LoginForm from '../components/LoginForm';
import logo from 'assets/imgs/UnbankLogo.svg';

import { useEffect } from 'react';
import storage from 'utils/storage';

import { PromotionalSegment } from '../components/PromotionalSegment';

const LoginPage = () => {
  useEffect(() => {
    // Clear any lingering auth data when the user first loads the login page.
    storage.clearAuthData();
  }, []);

  const currentYear = new Date().getFullYear();
  return (
    <div className="grid grid-cols-1 overflow-hidden md:grid-cols-2">
      <section className="flex flex-col justify-between border px-12 pt-20">
        <div className="my-auto">
          <div className="my-5 flex items-center  space-x-2 font-semibold text-primary">
            <a href="https://unbank.com/" rel="noreferrer" target="_blank">
              <img src={logo} className="h-7" alt="Unbank Logo" />
            </a>
          </div>

          <LoginForm />
        </div>
        <footer>
          <div className="my-2 text-tw-gray-500">
            Copyright &copy;Unbank {currentYear}
          </div>
        </footer>
      </section>
      <section className="hidden md:block">
        <PromotionalSegment />
      </section>
    </div>
  );
};

export default LoginPage;
