import { TextInput, TextInputProps } from '@mantine/core';

/** Temporary Props until redesign is complete */
interface TempProps {
  isAuth?: boolean;
}
type TextFieldProps = TempProps &
  TextInputProps &
  React.RefAttributes<HTMLInputElement>;

const TextField = ({ ...props }: TextFieldProps) => {
  return (
    <TextInput
      styles={
        props.isAuth
          ? { label: { fontSize: '12px' } }
          : {
              input: { fontSize: '18px' },
              label: { fontSize: '14px' },
            }
      }
      color="gray-dark"
      variant={props.isAuth ? 'default' : 'filled'}
      size="lg"
      {...props}
    />
  );
};

export default TextField;
