import { DepositLimitCard } from '../components/DepositLimitCard';
import { useAuth } from 'modules/auth';
import { Link } from 'react-router-dom';
import { detectViewport } from 'utils/layout';
import { useGetAccountInfo } from 'modules/account/api/getAccountInfo';
import { useEffect, useState } from 'react';
import { TransactionList } from 'modules/transactions/components/TransactionList/TransactionList';
import { useLimits } from 'modules/transactions/providers/limits';
import TickerCardList from '../components/CoinPrice/TickerCardList';

export const OverviewPage = () => {
  const { user, setUser } = useAuth();

  const [viewport, setViewport] = useState(detectViewport());

  // Create a function to update the viewport/device
  const updateViewport = () => {
    setViewport(detectViewport());
  };

  // Use the useEffect hook to detect any changes to the user's viewport/device
  useEffect(() => {
    window.addEventListener('resize', updateViewport);
    return () => window.removeEventListener('resize', updateViewport);
  });

  // Fetch user on first load
  const { update: fetchUserInfo } = useGetAccountInfo((resp) =>
    setUser(resp.data)
  );

  useEffect(() => {
    fetchUserInfo({});
  }, []);

  const { overallLimits } = useLimits();

  return (
    <div className="space-y-8 font-inter">
      <header className="border-b border-gray-medium mb-6 pb-3">
        <h3 className="text-xl font-semibold">Dashboard</h3>
      </header>
      <div className="flex items-center gap-x-4">
        <DepositLimitCard
          title="Daily Limits"
          limit={overallLimits?.daily_limit!}
          usage={overallLimits?.daily_sum!}
        />
        <DepositLimitCard
          title="Weekly Limits"
          limit={overallLimits?.weekly_limit!}
          usage={overallLimits?.weekly_sum!}
        />
        <DepositLimitCard
          title="Monthly Limits"
          limit={overallLimits?.monthly_limit!}
          usage={overallLimits?.monthly_sum!}
        />
      </div>
      <div className="flex gap-5">
        <section className="flex-grow">
          <header className="mb-12 flex items-center justify-between border-b border-gray-bdr pb-3">
            <h2 className="flex-grow text-2xl font-bold ">Transactions</h2>
            <Link to="/dashboard/transactions" className="text-primary-dark font-bold underline">
              See All
            </Link>
          </header>

          <TransactionList />
        </section>
        <section>
          <TickerCardList />
        </section>
      </div>
    </div>
  );
};
