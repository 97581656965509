import { Loader } from '@mantine/core';
import { numberFormatter } from 'utils/formatters';

interface TickerCardProps {
  children: React.ReactNode;
  name: string;
  symbol: string;
  amount: number;
  percentage: number;
  loading: boolean;
}

export const TickerCard = ({
  children,
  name,
  symbol,
  amount,
  percentage,
  loading,
}: TickerCardProps) => {
  return (
    <div className="flex w-[358px] items-center justify-between rounded-lg border border-gray-bdr px-6 py-3">
      {loading ? (
        <Loader className="mx-auto" />
      ) : (
        <>
          <div>
            <div className="mb-1 flex gap-x-2">
              {children}
              <h3 className="text-xs leading-3">
                {name} ({symbol})
              </h3>
            </div>
            <p className="text-xl font-bold">${numberFormatter(amount, 2)}</p>
          </div>
          <p
            className={`font-bold ${
              percentage > 0 ? 'text-success-700' : 'text-error-600'
            }`}
          >
            {percentage > 0 ? '↑' : '↓'}{percentage}
          </p>
        </>
      )}
    </div>
  );
};
