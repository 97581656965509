import {
  NotificationCategory,
  NotificationSetting,
} from 'modules/notifications/types/notifications';

/** Provides plaintext field for respective notification category

 */
export const getPlaintextCategory = (name: string) => {
  switch (name) {
    case NotificationCategory.ENABLE_EMAIL:
      return 'Email';
    case NotificationCategory.ENABLE_FCM:
      return 'Push';
    case NotificationCategory.ENABLE_WEB:
      return 'In Browser';
  }
};
export const isSetting = (settingKey: string) => {
  return Object.values(NotificationCategory).includes(
    settingKey as NotificationCategory
  );
};

export const getCategorySettings = (category: NotificationSetting) => {
  let settings = [];
  for (const [key, value] of Object.entries(category)) {
    if (isSetting(key) && value) {
      settings.push(key);
    }
  }
  return settings;
};

/**
 * Updates state of respective notification field
 * @param name
 * @param checked
 * @param state
 * @returns
 */
export const updateField = (
  name: string,
  checked: boolean,
  state?: NotificationSetting
) => {
  // If state isn't present don't update field
  if (state) {
    return { ...state, [name]: checked };
  }
};


