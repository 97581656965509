import { useEffect, useState } from 'react';
import { useClipboard } from '@mantine/hooks';
import { ClipboardIcon } from '@heroicons/react/outline';
import { Tooltip } from '@mantine/core';
import { useTwoFactorService } from 'modules/two-factor/providers';
import notification from 'utils/notification';
import Button from 'components/Button';
import { RecoveryCodeLoader } from './RecoveryCodeLoader';

export const RecoveryCodes = () => {
  const [recoveryCodes, setRecoveryCodes] = useState<string[]>();
  const { twoFactorService } = useTwoFactorService();
  const clipboard = useClipboard({ timeout: 500 });

  useEffect(() => {
    const fetchRecoveryCodes = async () => {
      const codes = await twoFactorService.getRecoveryCodes();
      setRecoveryCodes(codes);
    };

    fetchRecoveryCodes();
  }, []);

  const handleRecoveryCodeRegeneration = async () => {
    try {
      await twoFactorService.regenerateRecoveryCodes();
      notification.notify('Recovery Codes Successfully Regenerated');

      const newCodes = await twoFactorService.getRecoveryCodes();
      setRecoveryCodes(newCodes);
    } catch (error) {
      console.error('Error regenerating recovery codes:', error);
    }
  };

  return (
    <div>
      <h3 className="my-2 font-bold">Recovery Codes</h3>
      <p className="my-2">
        Save your recovery codes below somewhere secure in the event that your
        authentication device is lost
      </p>

      <div className="flex justify-around  bg-gray-medium  p-5">
        <div className="grid w-full grid-cols-2">
          {recoveryCodes
            ? recoveryCodes.map((code, index) => (
                <p key={index} className="text-sm text-gray-secondaryDark">
                  {code}
                </p>
              ))
            : Array(8)
                .fill(null)
                .map((_, index) => (
                  <span key={index} className="my-1">
                    <RecoveryCodeLoader />
                  </span>
                ))}
        </div>
        {recoveryCodes && (
          <Tooltip label="Copy Recovery Codes">
            <ClipboardIcon
              className="h-5 text-gray-secondaryDark hover:cursor-pointer"
              onClick={() => {
                clipboard.copy(recoveryCodes.join('\r\n'));
                notification.notify('Recovery Codes Successfully copied');
              }}
            />
          </Tooltip>
        )}
      </div>
      <div className="mt-5 flex justify-between">
        <Button
          color="bg-secondary"
          size="sm"
          onClick={() => {
            handleRecoveryCodeRegeneration();
          }}
        >
          Regenerate Recovery Codes
        </Button>
      </div>
    </div>
  );
};
