import { Accordion, Loader } from '@mantine/core';
import { SellLimits } from 'modules/transactions/components/SellLimits';
import {
  IBuyLimits,
  ISellLimits,
  TransactionLimit,
} from 'modules/transactions/types/limits';
import { currencyFormatter } from 'utils/formatters';

import { AccountBalanceWallet } from '@mui/icons-material';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { BuyLimits } from 'modules/transactions/components/BuyLimits';

interface AccountLevelAccordionProps {
  buyLimits: IBuyLimits;
  sellLimits: ISellLimits;
  overallLimits: TransactionLimit;
}
export const AccountLevelAccordion = ({
  buyLimits,
  sellLimits,
  overallLimits,
}: AccountLevelAccordionProps) => {
  return (
    <>
      <Accordion
        iconPosition="right"
        classNames={{
          item: 'border-none',
        }}
      >
        <Accordion.Item
          label={
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <AccountBalanceWallet className="h-12 text-primary" />

                <p> Buy Cryptocurrency</p>
              </div>
              <p>
                {currencyFormatter(overallLimits?.weekly_limit || 0)} /
                <span className="text-sm text-tw-gray-500"> Week</span>
              </p>
            </div>
          }
        >
          {buyLimits ? (
            <BuyLimits limits={buyLimits} />
          ) : (
            <div className="text-center">
              <Loader />
            </div>
          )}
        </Accordion.Item>
      </Accordion>
      <hr className="my-2 border-t border-tw-gray-300" />
      <Accordion
        iconPosition="right"
        classNames={{
          item: 'border-none',
        }}
      >
        <Accordion.Item
          label={
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <PaperAirplaneIcon className="h-8 rotate-90 text-primary" />
                <p> Sell Cryptocurrency</p>
              </div>
              <p>
                {currencyFormatter(overallLimits?.weekly_limit || 0)} /
                <span className="text-sm text-tw-gray-500"> Week</span>
              </p>
            </div>
          }
        >
          {sellLimits ? (
            <SellLimits limits={sellLimits} />
          ) : (
            <div className="text-center">
              <Loader />
            </div>
          )}
        </Accordion.Item>
      </Accordion>
    </>
  );
};
