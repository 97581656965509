import { Transaction, getPaymentType } from 'modules/transactions';
import { capitalizeFirstLetter, numberFormatter } from 'utils/formatters';
// import { ArrowSmRightIcon } from '@heroicons/react/solid';
import { StyledOrderStatus } from 'components/StyledOrderStatus';
import { ArrowRightIcon } from 'components/icons';

type TransactionCardProps = {
  transaction: Transaction;
  onSelectTransaction: (transaction: Transaction) => void;
};

export const TransactionCard = ({
  transaction,
  onSelectTransaction,
}: TransactionCardProps) => {
  return (
    <article
      className="flex justify-between py-3.5 px-3"
      onClick={() => onSelectTransaction}
    >
      <div className="">
        <div className="flex items-center gap-x-3">
          <p className="font-bold">{transaction.order_id.slice(0,10) }</p>
          <div>
            <StyledOrderStatus orderStatus={transaction.status} />
          </div>
        </div>
        <p className="text-sm">{getPaymentType(transaction.service_provider)}</p>
      </div>
      <div className="">
        <div className="flex items-center gap-3 font-semibold">
          <p>{transaction.amount}{transaction.currency}</p>
          <ArrowRightIcon className="h-7 text-gray-800" />
          <p> {numberFormatter(transaction.amount_in_crypto, 8)}{transaction.crypto} </p>
        </div>
        <div className="flex items-center justify-end gap-x-3 mt-2">
          <span
            className={`text-xs ${
              transaction.transaction_type === 'buy'
                ? 'bg-gray-100 text-secondary'
                : 'bg-light-green text-primary'
            } py-0.5 px-2  font-semibold rounded-full`}
          >
            {capitalizeFirstLetter(transaction.transaction_type)}
          </span>
          <p className="text-sm text-gray-500">{ new Date(transaction.transfer_date_utc).toLocaleDateString("en", { weekday: 'short', day: "2-digit", month: "short", year: "numeric"})}</p>
        </div>
      </div>
    </article>
  );
};
