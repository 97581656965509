export const TransactionIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9444 3.88892H2.05547C1.38046 3.88892 0.833252 4.43612 0.833252 5.11114V14.8889C0.833252 15.5639 1.38046 16.1111 2.05547 16.1111H17.9444C18.6194 16.1111 19.1666 15.5639 19.1666 14.8889V5.11114C19.1666 4.43612 18.6194 3.88892 17.9444 3.88892Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.86144 12.4272C11.2115 12.4272 12.3059 11.3328 12.3059 9.98277C12.3059 8.63275 11.2115 7.53833 9.86144 7.53833C8.51141 7.53833 7.41699 8.63275 7.41699 9.98277C7.41699 11.3328 8.51141 12.4272 9.86144 12.4272Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
