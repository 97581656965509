import { useState } from 'react';
import Button from 'components/Button';
import { Loader, Stepper } from '@mantine/core';
import { CheckIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { useAuth } from 'modules/auth';
import { useLimits } from 'modules/transactions/providers/limits';
import { AccountLevelAccordion } from 'modules/identity-verification/components/AccountLevelAccordion';
import { getVerificationStatus } from 'modules/identity-verification/util/verification';
import ResponsiveModal from 'components/Modal';
import { ContinueProcess } from 'modules/identity-verification/components/ContinueProcess';

export const IdentityVerification = () => {
  const { user } = useAuth();
  const { buyLimits, sellLimits, overallLimits } = useLimits();

  const [active, setActive] = useState(user?.account_level || 1);
  const [mitekModalOpened, setMitekModalOpened] = useState(false);

  const nextStep = () =>
    setActive((current) => (current < 5 ? current + 1 : current));

  return (
    <div className="grid grid-cols-1 gap-5 pb-10 md:grid-cols-2">
      <Stepper
        classNames={{
          root: 'w-full h-full',
          stepIcon: 'text-primary bg-white border-primary h-12 w-12 ',
          separator: 'invisible',
          // separator: ' h-[10px] bg-white',
        }}
        active={active}
        orientation="vertical"
        size="lg"
      >
        <Stepper.Step
          label={
            <div>
              <p className="my-1 text-sm">
                {' '}
                {getVerificationStatus(active, 1)}{' '}
              </p>
              <h2> Level 1</h2>
            </div>
          }
          description={
            <div>
              <p>
                Buy and Sell Cryptocurrency using Unbank Cash, Paypal, Venmo and
                at ATM
              </p>
              <div className="my-2">
                {user && user?.account_level >= 1 ? (
                  <div>
                    <div className="flex items-center gap-2 font-bold text-black">
                      <CheckIcon className="h-5" />
                      <p>Email Verified</p>
                    </div>
                    <div className="my-3 flex items-center gap-2 font-bold text-black">
                      <CheckIcon className="h-5" />
                      <p>Phone Number Verified</p>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center gap-2 font-bold text-black">
                    <CheckIcon className="h-5" />
                    <p>Please verify your Email Address</p>
                  </div>
                )}
              </div>
            </div>
          }
        ></Stepper.Step>
        <Stepper.Step
          label={
            <div>
              <p className="my-1 text-sm">{getVerificationStatus(active, 2)}</p>
              <h2> Level 2</h2>
            </div>
          }
          description={
            <div>
              <p>Buy and Sell cryptocurrency using Bank Card</p>
              {active >= 2 ? (
                <div className="my-2">
                  <div className="flex items-center gap-2 font-bold text-black">
                    <CheckIcon className="h-5" />
                    <p>
                      Passport, Drivers License or Identification Card Verified
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="my-3 flex items-center gap-2 font-bold text-black">
                    <QuestionMarkCircleIcon className="h-5" />
                    <p>
                      Passport, Drivers License or Identification Card
                      Verification Required
                    </p>
                  </div>
                  <Button
                    className="py-3"
                    size="sm"
                    onClick={() => setMitekModalOpened(true)}
                  >
                    Complete Verifiction
                  </Button>
                </div>
              )}
            </div>
          }
        >
          <ResponsiveModal
            opened={mitekModalOpened}
            size="3xl"
            onClose={() => setMitekModalOpened(false)}
          >
            <ContinueProcess />
          </ResponsiveModal>
        </Stepper.Step>
        <Stepper.Step
          label={
            <div>
              <p className="my-1 text-sm">
                {' '}
                {getVerificationStatus(active, 3)}
              </p>
              <h2> Level 3</h2>
            </div>
          }
          description={
            <div>
              <p>Buy Cryptocurrency at ATM and Sell cryptocurrency via ACH</p>
              {active >= 3 ? (
                <div className="my-2">
                  <div className="flex items-center gap-2 font-bold text-black">
                    <CheckIcon className="h-5" />
                    <p>SSN Verified</p>
                  </div>
                </div>
              ) : (
                <div className="my-3 flex items-center gap-2 font-bold text-black">
                  <QuestionMarkCircleIcon className="h-5" />
                  <p>SSN Verification Required</p>
                </div>
              )}
            </div>
          }
        ></Stepper.Step>
      </Stepper>
      <div className="order-first my-5 md:order-last md:my-0">
        <h2 className="mb-4"> Account Limits </h2>
        {buyLimits && sellLimits && overallLimits ? (
          <AccountLevelAccordion
            buyLimits={buyLimits}
            sellLimits={sellLimits}
            overallLimits={overallLimits}
          />
        ) : (
          <div className="text-center">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};
