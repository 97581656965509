import { RouteObject } from 'react-router-dom';
import { General } from '../components/General';
import { IdentityVerification } from 'modules/identity-verification/routes/IdentityVerification';
import { PaymentMethods } from 'modules/payment-methods';
import { Account } from '../pages/Account';
import { TwoFactorAuth } from 'modules/two-factor/pages';
import { WalletSettings } from 'modules/wallet/components/WalletSettings';
import { CashoutMethods } from '../components/CashoutMethods';
import { NotificationPreferences } from '../components/NotificationPreferences';

export const routes: RouteObject[] = [
  {
    path: 'account/',
    element: <Account />,
    children: [
      {
        path: 'general',
        element: <General />,
      },
      {
        path: 'payment-methods',
        element: <PaymentMethods />,
      },
      {
        path: 'wallet',
        element: <WalletSettings />,
      },
      {
        path: 'identity-verification',
        element: <IdentityVerification />,
      },
      {
        path: 'security',
        element: <TwoFactorAuth />,
      },
      {
        path: 'cashout-methods',
        element: <CashoutMethods />,
      },
      {
        path: 'notification-preferences',
        element: <NotificationPreferences />,
      },
    ],
  },
];
