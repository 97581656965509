export const LitecoinIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_388_8893)">
        <path
          d="M6.00002 11.3506C8.95508 11.3506 11.3506 8.95506 11.3506 6C11.3506 3.04495 8.95508 0.649399 6.00002 0.649399C3.04496 0.649399 0.649414 3.04495 0.649414 6C0.649414 8.95506 3.04496 11.3506 6.00002 11.3506Z"
          fill="white"
        />
        <path
          d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259969 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C12.0023 5.21436 11.8498 4.43596 11.5513 3.70924C11.2527 2.98252 10.814 2.32173 10.2601 1.76457C9.70617 1.20742 9.04794 0.764827 8.32298 0.46206C7.59801 0.159294 6.82051 0.00228606 6.03487 0H6ZM6.1017 6.20339L5.477 8.30993H8.81841C8.84054 8.30915 8.86261 8.31275 8.88335 8.3205C8.9041 8.32826 8.92311 8.34004 8.9393 8.35515C8.95549 8.37026 8.96854 8.38841 8.97771 8.40857C8.98688 8.42873 8.99199 8.4505 8.99274 8.47264V8.52785L8.70218 9.53027C8.68937 9.57767 8.66082 9.61931 8.62122 9.64835C8.58162 9.67739 8.53333 9.6921 8.48426 9.69007H3.37046L4.22761 6.76998L3.26877 7.06053L3.48669 6.39225L4.44552 6.1017L5.65133 2.00484C5.66461 1.9577 5.69327 1.91636 5.73277 1.8874C5.77226 1.85844 5.8203 1.84353 5.86925 1.84504H7.16223C7.18436 1.84426 7.20643 1.84785 7.22718 1.85561C7.24792 1.86337 7.26693 1.87514 7.28312 1.89026C7.29932 1.90537 7.31237 1.92352 7.32154 1.94368C7.33071 1.96384 7.33581 1.98561 7.33656 2.00775V2.06295L6.31962 5.52058L7.27845 5.23002L7.07506 5.92736L6.1017 6.20339Z"
          fill="#345D9D"
        />
      </g>
      <defs>
        <clipPath id="clip0_388_8893">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
