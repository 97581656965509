import QRCode from 'react-qr-code';

const VERIFICATION_LINK = 'https://app.unbankworld.com/verify/identity';

export const ContinueProcess = () => {
  return (
    <div>
      <h2 className="mb-8 text-center">Continue Verification Process</h2>

      <div className="justi mt-8 flex flex-col-reverse lg:flex-row">
        <div className="mx-auto w-2/3 pr-4 lg:mx-0">
          <ul className="ml-5 list-outside list-disc space-y-2">
            <li>Submit clear photos of your ID Card or Driver's License.</li>
            <li>Do not edit photos to be submitted in any way.</li>
            <li>Photos of screens or screenshots are not accepted.</li>
            <li>
              Documents used must e valid (not expired), U.S. Government issued,
              legible and in color.
            </li>
            <li>Do not use your U.S. passport.</li>
          </ul>
          <h3 className="mt-8 mb-2 font-bold text-red-500">PLEASE NOTE!!!</h3>
          <p>
            Your state and address will be scanned from the documents you
            submit.
          </p>
        </div>

        {/* Right */}
        <div className="mx-auto mb-8 flex flex-col items-center text-center lg:mx-0 lg:w-1/3">
          <p className="mb-4">Scan the QR Code with your phone</p>
          <QRCode value={VERIFICATION_LINK} />
        </div>
      </div>
    </div>
  );
};
