import { Checkbox, Divider, Loader } from '@mantine/core';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import storage from 'utils/storage';
import { LoginCredentialsDTO } from '../api/login';
import { LoginApiResponse } from '../types/login';
import { useAuth } from '../providers';
import ForgotPasswordModal from './ForgotPasswordModal';
import { ChangeEvent, useEffect, useState } from 'react';
import PasswordField from 'components/PasswordField';
import { useTwoFactorService } from 'modules/two-factor/providers';
import { useAccountService } from 'modules/account/providers/AccountProvider';
import ResponsiveModal from 'components/Modal';
import PhoneNumberField from 'components/PhoneNumberField';

const LoginForm = () => {
  const { setToken, authService, setUser } = useAuth();
  const { setIsTwoFactorAuthenticated } = useTwoFactorService();
  const [forgotPasswordModalOpened, setForgotPasswordModalOpened] =
    useState(false);
  const navigate = useNavigate();
  const accountService = useAccountService();

  const [rememberMe, setRememberMe] = useState(storage.getRememberMe());
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik<LoginCredentialsDTO>({
    initialValues: {
      phone_number: storage.getPhone(),
      password: '',
    },
    validationSchema: Yup.object({
      phone_number: Yup.string().required('Phone number is required.'),
      password: Yup.string().required('Password is required.'),
    }),
    onSubmit: (values) => {
      loginUser(values);
    },
  });

  /**
   * Handle the success of a login attempt.
   * Stores the received auth token, updates state regarding two-factor authentication,
   * @param data - The response data from the login API.
   */
  const handleLoginSuccess = async (data: LoginApiResponse) => {
    const authToken = data.unbank.token;
    setIsTwoFactorAuthenticated(() => data.two_factor);
    setToken(authToken);

    storage.setToken(authToken);

    if (data.two_factor) {
      navigate('/auth/2fa-verify');
    } else {
      // Fetch and set user on successful login
      try {
        const response = await accountService.fetchAccountData();
        const user = response.data.data;
        if (user) {
          setUser(user);
          navigate('/dashboard/overview');
        }
      } catch (error) {
        console.error('Failed to fetch account data:', error);
      }
    }
  };

  /**
   * Sanitize a phone number by removing spaces and special characters.
   * @param phoneNumber - The phone number string to sanitize.
   */
  const sanitizePhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/[^0-9]+/g, '');
  };

  /**
   * Handle the user login process.
   * Makes an API call to login the user and handles the result.
   * @param crendentials - The user's login credentials.
   */
  const loginUser = async (crendentials: LoginCredentialsDTO) => {
    // Before sending the request, sanitize the phone number
    crendentials.phone_number = sanitizePhoneNumber(crendentials.phone_number);

    setIsRequestLoading(true);
    try {
      const resp = await authService.login(crendentials);
      handleLoginSuccess(resp.data);
    } catch (err) {
      console.error(err);
      setIsRequestLoading(false);
    } finally {
      setIsRequestLoading(false);
    }
  };

  useEffect(() => {
    storage.setRememberMe(rememberMe);
    if (rememberMe) {
      storage.setPhone(values.phone_number);
    } else {
      storage.clearPhone();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-xl font-inter">
      <header>
        <h1 className="font-satoshi text-2xl font-bold md:text-3xl">
          Welcome Back!
        </h1>
        <p className="text-gray-600">Enter your login details below</p>
      </header>
      <div className="my-5 space-y-5">
        <PhoneNumberField
          className="my-5"
          label="Phone number"
          placeholder="+1 (555) 000-0000"
          name="phone_number"
          value={values.phone_number}
          onChange={(phone) => setFieldValue('phone_number', phone)}
          onBlur={() => handleBlur('phone_number')}
          dataTestId="login-form-phone-input"
          error={
            errors.phone_number && touched.phone_number
              ? errors.phone_number
              : ''
          }
        />

        <PasswordField
          size="md"
          isAuth={true}
          className="shadow-sm"
          label="Password"
          placeholder="Password"
          name="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.password && touched.password && errors.password}
          data-testid="login-form-password-input"
        />
        <div className="flex items-center justify-between">
          <Checkbox
            label="Remember Me"
            classNames={{ input: 'border-gray-300' }}
            checked={rememberMe}
            onChange={handleCheckbox}
          />
          <button
            onClick={() => setForgotPasswordModalOpened(true)}
            type="button"
            className="cursor-pointer font-bold text-gray-700 transition-colors ease-in hover:text-primary hover:underline"
          >
            Forgot your password?
          </button>
        </div>
        <div>
          <button
            disabled={isRequestLoading}
            className="btn w-full text-white"
            data-testid="login-form-submit-button"
            type="submit"
          >
            {isRequestLoading ? (
              <Loader
                data-testid="custom-button-loader"
                size="md"
                color="white"
              />
            ) : (
              <>
                <p className="font-bold">Sign In</p>
              </>
            )}
          </button>
          <Divider my="sm" />

          <div className="text-gray-700">
            Don't have an account?{' '}
            <Link
              to="/auth/register"
              className="font-bold text-primary hover:underline"
            >
              Register Here
            </Link>
          </div>
        </div>
      </div>
      <ResponsiveModal
        opened={forgotPasswordModalOpened}
        onClose={() => setForgotPasswordModalOpened(false)}
        size="xl"
      >
        <ForgotPasswordModal />
      </ResponsiveModal>
    </form>
  );
};

export default LoginForm;
