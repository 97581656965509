type ProgressBarProps = {
  progressPercentage: number;
};

export const ProgressBar = ({ progressPercentage }: ProgressBarProps) => {
  return (
    <div
      data-testid="custom-progress-bar"
      className="relative h-3 w-full overflow-hidden rounded-full bg-gray-300"
    >
      <div
        style={{
          width: `${progressPercentage}%`,
        }}
        className={`absolute top-0 left-0 bottom-0 rounded-full bg-primary`}
      />
    </div>
  );
};
