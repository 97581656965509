import { useState } from 'react';

import Button from 'components/Button';
import { TwoFactorSetupModal } from 'modules/two-factor/components/TwoFactorSetupModal';
import { Loader } from '@mantine/core';
import { ConfirmPassword } from 'modules/two-factor/components/ConfirmPassword';
import { RecoveryCodes } from 'modules/two-factor/components/RecoveryCodes';
import { useAuth } from 'modules/auth';
import { useTwoFactorService } from '../providers';
import ResponsiveModal from 'components/Modal';
import notification from 'utils/notification';

export const TwoFactorAuth = () => {
  const [setupModalOpened, setSetupModalOpened] = useState(false);
  const [recoveryModalOpened, setRecoveryModalOpened] = useState(false);
  const [disableModalOpened, setDisableModalOpened] = useState(false);
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);

  const { user } = useAuth();
  const { twoFactorService } = useTwoFactorService();

  const refreshPage = () => {
    window.location.reload();
  };

  const handleQrDisable = async () => {
    const resp = await twoFactorService.disableTwoFactorAuth();

    if (resp.data.status && resp.data.status.toLowerCase() === 'success') {
      notification.notify('Two-Step Authentication Disabled');
    }
  };

  return (
    <div>
      <header>
        <h1 className="text-2xl font-bold">Two-Step Authentication</h1>
        <div className="flex items-center justify-between">
          <div className="my-4 w-3/5">
            <p className="mb-4">
              {' '}
              Add additional security to your account using two-step
              authentication{' '}
            </p>{' '}
            <p>
              {' '}
              When Two Step Authentication is enabled, you will be prompted for
              a secure, random token during authentication. You may retrieve
              this token from your phone's Google Authenticator application.
            </p>
          </div>
          <article>
            {user ? (
              <div>
                {user?.two_factor ? (
                  <div>
                    <article className="flex items-center gap-10">
                      <div className="flex">
                        <Button
                          size="sm"
                          className="mx-3 border border-primary bg-white text-primary"
                          onClick={() => {
                            setRecoveryModalOpened(true);
                          }}
                        >
                          {' '}
                          Show Recovery Codes{' '}
                        </Button>
                        <Button
                          className="bg-red-500"
                          type="submit"
                          size="sm"
                          onClick={() => {
                            setDisableModalOpened(true);
                          }}
                        >
                          Disable
                        </Button>
                      </div>
                      {/* Confirm Password before showing recovery codes */}
                      <ResponsiveModal
                        title={
                          <h2>Two Step Authentication - Recovery Codes</h2>
                        }
                        opened={recoveryModalOpened}
                        onClose={() => setRecoveryModalOpened(false)}
                        size="50%"
                      >
                        {passwordConfirmed ? (
                          <RecoveryCodes />
                        ) : (
                          <ConfirmPassword
                            handlePasswordConfirmed={() => {
                              setPasswordConfirmed(true);
                            }}
                          />
                        )}
                      </ResponsiveModal>

                      {/* Confirm Password before disabling 2FA */}
                      <ResponsiveModal
                        title={<h2> Disable Two Step Authentication </h2>}
                        opened={disableModalOpened}
                        onClose={() => {
                          setDisableModalOpened(false);
                          refreshPage();
                        }}
                        size="50%"
                      >
                        <ConfirmPassword
                          handlePasswordConfirmed={() => {
                            setPasswordConfirmed(true);
                            handleQrDisable();
                            setDisableModalOpened(false);
                          }}
                        />
                      </ResponsiveModal>
                    </article>
                  </div>
                ) : (
                  <article className="flex items-center gap-10">
                    <Button
                      type="submit"
                      size="sm"
                      onClick={() => setSetupModalOpened(true)}
                    >
                      Enable
                    </Button>
                    {/* Display Recover Code Modal*/}
                    <TwoFactorSetupModal
                      opened={setupModalOpened}
                      onClose={() => {
                        setSetupModalOpened(false);
                        refreshPage();
                      }}
                    />
                  </article>
                )}
              </div>
            ) : (
              <div>
                <Loader />
              </div>
            )}
          </article>
        </div>
      </header>
    </div>
  );
};
