import type { AxiosInstance } from "axios";
import { LoginApiResponse } from 'modules/auth/types/login';
import { LoginCredentialsDTO } from "../api/login";


export default class AuthenticationService {
    axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    login(credentials: LoginCredentialsDTO) {
        return this.axios.post<LoginApiResponse>('/api/login', {
            ...credentials,
        });
    };
}