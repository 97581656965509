import { verifyTwoFactor } from 'modules/account';
import { useState } from 'react';
import * as Yup from 'yup';
import TextField from 'components/TextField';
import logo from 'assets/imgs/unbank-logo.svg';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import storage from 'utils/storage';
import { useAuth } from 'modules/auth/providers';
import { useAccountService } from 'modules/account/providers/AccountProvider';
import { useTwoFactorService } from 'modules/two-factor/providers';

export const TwoFactorVerify = () => {
  // API returns QR Response or Empty array
  const [authType, setAuthType] = useState('authenticator');
  const { setToken, setUser } = useAuth();

  const navigate = useNavigate();
  const accountService = useAccountService();
  const { twoFactorService, setIsTwoFactorValid } = useTwoFactorService();

  const { handleChange, handleSubmit } = useFormik<{ code: string }>({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      const result = await twoFactorService.verifyTwoFactor(
        values.code,
        authType
      );

      const authToken = result.data.unbank.token;

      if (authToken) {
        setToken(authToken);

        storage.setToken(authToken);
        setIsTwoFactorValid(true);

        // Fetch and set User
        accountService.fetchAccountData().then((resp) => {
          setUser(resp.data.data);
          navigate('/dashboard/overview');
        });
      }
    },
  });
  return (
    <div className="my-12">
      <header>
        <div className="mx-8 flex  flex-col items-center justify-center lg:mx-auto">
          <div className="mb-2 rounded-lg bg-primary p-2">
            <a href="https://unbankworld.com/">
              <img src={logo} className="h-11 lg:h-14 " alt="Unbank Logo" />
            </a>
          </div>
        </div>
      </header>
      <main className="flex flex-col items-center text-center ">
        <h1>Two Factor Authentication</h1>
        <p className="mb-4 w-2/5 text-sm text-gray-secondaryDark ">
          {' '}
          Thanks for keeping your account secure. Enter your verification code
          from your authenticator app or one of your recovery codes to access
          your unbank dashboard!
        </p>

        <section className="">
          <form onSubmit={handleSubmit}>
            <TextField
              name="code"
              onChange={handleChange}
              className="w-full py-2"
              placeholder={
                authType === 'authenticator'
                  ? 'Verification Code'
                  : 'Recovery Code'
              }
            ></TextField>
            <div className="flex items-center justify-center">
              <Button type="submit" size="sm">
                Log in securely
              </Button>{' '}
              {authType === 'authenticator' ? (
                <p
                  onClick={() => {
                    setAuthType('recovery_code');
                  }}
                  className="mt-3 ml-5 text-sm text-primary hover:cursor-pointer"
                >
                  {' '}
                  Use Recovery Code{' '}
                </p>
              ) : (
                <p
                  onClick={() => {
                    setAuthType('authenticator');
                  }}
                  className="mt-3 ml-5 text-sm text-primary hover:cursor-pointer"
                >
                  {' '}
                  Use Code from Authenticator App{' '}
                </p>
              )}
            </div>
          </form>
        </section>
      </main>
    </div>
  );
};
