import { useEffect, useState } from 'react';
import { getNotificationSettings } from '../api/notificationSettings';
import {
  NotificationSetting,
  NotificationTypes,
  NotificationCategory,
} from 'modules/notifications/types/notifications';
import { Loader } from '@mantine/core';
import { NotificationCard } from './NotificationPreferences/NotificationCard';
import { NotificationPreference } from './NotificationPreferences/NotificationPreference';
import { getCategorySettings } from 'modules/notifications/util';

export const NotificationPreferences = () => {
  const [settings, setSettings] = useState<NotificationSetting[]>();

  // Notification State
  const [btcNotifications, setBtcNotifications] =
    useState<NotificationSetting>();

  const [ltcNotifications, setLtcNotifications] =
    useState<NotificationSetting>();

  const [securityAlert, setSecurityAlert] = useState<NotificationSetting>();

  const [productAnnouncement, setProductAnnouncement] =
    useState<NotificationSetting>();

  // Toggle setting visibility
  const [notificationCategory, setNotificationCategory] = useState<string>('');

  const toggleCategoryVisible = (category: NotificationTypes) => {
    setNotificationCategory(category as string);
  };

  const getSelectedCategory = (category: NotificationTypes) => {
    switch (category) {
      case NotificationTypes.BTC_PRICE_ALERT:
        return btcNotifications;

      case NotificationTypes.LTC_PRICE_ALERT:
        return ltcNotifications;

      case NotificationTypes.SECURITY_ALERT:
        return securityAlert;
      case NotificationTypes.PRODUCT_ANNOUNCEMENT:
        return productAnnouncement;
    }
  };

  useEffect(() => {
    // Pull list of notification settings
    const fetchSettings = async () => {
      const notificationSettings = await getNotificationSettings();
      setSettings(notificationSettings.data);
    };

    fetchSettings().catch(console.error);
  }, []);

  useEffect(() => {
    // Initialzie state of settings
    const initializeSettings = () => {
      if (settings) {
        for (let setting of settings) {
          switch (setting.name) {
            case NotificationTypes.BTC_PRICE_ALERT:
              setBtcNotifications(setting);
              break;

            case NotificationTypes.LTC_PRICE_ALERT:
              setLtcNotifications(setting);
              break;

            case NotificationTypes.SECURITY_ALERT:
              setSecurityAlert(setting);
              break;
            case NotificationTypes.PRODUCT_ANNOUNCEMENT:
              setProductAnnouncement(setting);
          }
        }
      }
    };

    initializeSettings();
  }, [settings]);

  if (notificationCategory) {
    return (
      <NotificationPreference
        onExit={() => {
          setNotificationCategory('');
        }}
        settings={
          getSelectedCategory(notificationCategory as NotificationTypes)!
        }
      />
    );
  } else {
    return (
      <section>
        {settings ? (
          <div>
            <h1 className="text-2xl font-bold">Notification Preferences</h1>
            <p>Choose what notification you want to recieve.</p>

            <div>
              {securityAlert && (
                <NotificationCard
                  notificationCategories={
                    getCategorySettings(securityAlert) as NotificationCategory[]
                  }
                  name={'Security Alerts'}
                  setVisible={() =>
                    toggleCategoryVisible(NotificationTypes.SECURITY_ALERT)
                  }
                />
              )}
              {productAnnouncement && (
                <NotificationCard
                  notificationCategories={
                    getCategorySettings(
                      productAnnouncement
                    ) as NotificationCategory[]
                  }
                  name={'Product Announcements'}
                  setVisible={() =>
                    toggleCategoryVisible(
                      NotificationTypes.PRODUCT_ANNOUNCEMENT
                    )
                  }
                />
              )}
              {btcNotifications && (
                <NotificationCard
                  notificationCategories={
                    getCategorySettings(
                      btcNotifications
                    ) as NotificationCategory[]
                  }
                  name={'BTC Price Alerts'}
                  setVisible={() =>
                    toggleCategoryVisible(NotificationTypes.BTC_PRICE_ALERT)
                  }
                />
              )}

              {ltcNotifications && (
                <NotificationCard
                  notificationCategories={
                    getCategorySettings(
                      ltcNotifications
                    ) as NotificationCategory[]
                  }
                  name={'LTC Price Alerts'}
                  setVisible={() =>
                    toggleCategoryVisible(NotificationTypes.LTC_PRICE_ALERT)
                  }
                />
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-center">
            <Loader size="lg" />
          </div>
        )}
      </section>
    );
  }
};
