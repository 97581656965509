
import * as Yup from 'yup';
import TextField from 'components/TextField';
import { useFormik } from 'formik';
import Button from 'components/Button';

import { useTwoFactorService } from '../providers';

interface ConfirmTwoFactorProps {
  handleTwoFactorConfirmed: () => void;
}
export const ConfirmTwoFactor = ({
  handleTwoFactorConfirmed,
}: ConfirmTwoFactorProps) => {
  const { twoFactorService } = useTwoFactorService();

  // API returns QR Response or Empty array
  const { handleChange, handleSubmit } = useFormik<{ code: string }>({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      try {
        await twoFactorService.confirmTwoFactor(values.code, 'authenticator');

        handleTwoFactorConfirmed();
      } catch (err) {
        console.error(err);
      }
    },
  });
  return (
    <div className="">
      <main className=" ">
        <p className="mb-4 text-sm text-gray-secondaryDark ">
          For your security, please confirm your two factor code from your
          authenticator app to continue.
        </p>
        <section className="">
          <form onSubmit={handleSubmit}>
            <TextField
              name="code"
              onChange={handleChange}
              className="w-full py-2"
              placeholder="Verification Code"
            ></TextField>
            <div>
              <Button type="submit" size="sm">
                Confirm
              </Button>{' '}
            </div>
          </form>
        </section>
      </main>
    </div>
  );
};
