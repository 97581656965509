import GooglePlayIcon from 'assets/imgs/GooglePlayIcon.png';
import AppleStoreIcon from 'assets/imgs/AppStoreIcon.png';
import AppPreviewPhone from 'assets/imgs/AppPreviewPhone.png';

export const PromotionalSegment = () => {
  return (
    <section
      style={{
        background:
          'radial-gradient(88.5% 62.4% at 50% 50%, #3CCE49 0%, #135A45 100%)',
      }}
      className="flex h-screen flex-col overflow-hidden px-2 pt-5 md:px-5 lg:px-10"
    >
      <div className="flex flex-grow flex-col items-center px-2 md:px-5">
        <div className="mt-10">
          <h2 className="text-center font-satoshi text-xl text-white md:my-5 md:text-3xl lg:text-4xl">
            Also check out our mobile app <br className="hidden md:block" /> for
            Bitcoin Transfers on the go.
          </h2>
        </div>
        <div className="my-5 flex gap-5  md:gap-8  lg:gap-10">
          <a
            href="https://apps.apple.com/us/app/unbank-buy-and-sell-bitcoin/id1587374229"
            rel="noreferrer"
            target="_blank"
          >
            <img
              alt="google play icon"
              src={GooglePlayIcon}
              className="w-24 md:w-32 lg:w-40"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=world.unbank.unbankmobileapp"
            rel="noreferrer"
            target="_blank"
          >
            <img
              alt="app store icon"
              src={AppleStoreIcon}
              className="w-24 md:w-32 lg:w-40"
            />
          </a>
        </div>
      </div>
      <img
        src={AppPreviewPhone}
        alt="app preview"
        className="mt-5 h-[30rem] self-center"
      />
    </section>
  );
};
