import countries from 'assets/countries.json';

export const getCountryDialCode = (countryCode: string) => {
    const country = countries.find(country => country.code === countryCode);
    return country ? country.dial_code : null;
};

export const countriesList = countries.map(country => ({
    name: country.name,
    code: country.code,
    dialCode: country.dial_code,
}));
