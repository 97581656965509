import type { AxiosInstance } from "axios";

type GetExchangeRateDTO = {
    from: string;
    to: string;
    exchange?: number;
};

export default class ExchangeRateService {
    axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    fetchExchangeRate({ from, to, exchange = 1 }: GetExchangeRateDTO) {
        return this.axios.get(`/api/exchange/rates?from=${from}&to=${to}${exchange ? '&exchange=' + exchange : ''}`)
        .then(({ data }) => data)
        .catch(console.log);
    }
}