import { TwoFactorQRResponse } from 'modules/two-factor/types/twoFactor';
import { useEffect, useState } from 'react';
import { Loader } from '@mantine/core';
import { useTwoFactorService } from '../providers';

export const TwoFactorQR = () => {
  // State data for 2FA QR
  const [qrCode, setQrCode] = useState<TwoFactorQRResponse>();
  const [secretKey, setSecretKey] = useState<string>('');

  const { twoFactorService } = useTwoFactorService();

  useEffect(() => {
    const fetchQRData = async () => {
      const qrCode = await twoFactorService.getTwoFactorQR();
      const secretKey = await twoFactorService.getTwoFactorSecretKey();

      if (qrCode) {
        setQrCode(qrCode.data);
      }

      if (secretKey) {
        setSecretKey(secretKey.data.secretKey);
      }
    };

    fetchQRData();
  }, [twoFactorService]);

  return (
    <div>
      <p className="pb-2">
        Scan the image below with the two factor authentication app on your
        mobile device. If you can't do this enter the text below instead
      </p>
      {qrCode?.svg ? (
        <div>
          <div
            data-testid="two-factor-qr"
            className="my-5 flex flex-col items-center text-center"
            dangerouslySetInnerHTML={{ __html: qrCode.svg }}
          ></div>

          <div>
            <p className="mb-5  bg-gray-medium p-3 text-center text-sm text-gray-secondaryDark  ">
              {secretKey}
            </p>
          </div>
        </div>
      ) : (
        <div className="my-5 flex flex-col items-center text-center">
          <p className="my-3 font-bold"> Generating QR Code</p>
          <Loader data-testid="two-factor-qr-loader" color="green" size="xl" />;
        </div>
      )}
    </div>
  );
};
