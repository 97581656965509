import type { AxiosInstance } from "axios";
import { LoginApiResponse } from 'modules/auth/types/login';
import { TwoFactorQRResponse, TwoFactorResponse } from 'modules/two-factor/types/twoFactor';


export default class TwoFactorService {
    axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    enableTwoFactorAuth() {
        return this.axios.post<TwoFactorResponse>('/api/account/two-factor-authentication', {
            type: 'authenticator'
        });
    }

    disableTwoFactorAuth() {
        return this.axios.delete<TwoFactorResponse>('/api/account/two-factor-authentication', {
            data: {
                type: 'authenticator'
            }
        });
    }

    verifyTwoFactor(code: string, type: string) {
        return this.axios.post<LoginApiResponse>('/api/account/two-factor-challenge', {
            code: code,
            type: type
        })
    }

    confirmTwoFactor(code: string, type: string) {
        return this.axios.post<LoginApiResponse>('/api/account/confirmed-two-factor-authentication', {
            code: code,
            type: type
        })
    }

    getTwoFactorQR() {
        return this.axios.get<TwoFactorQRResponse>('/api/account/two-factor-qr-code');
    }

    getTwoFactorSecretKey() {
        return this.axios.get<{ secretKey: string }>('api/account/two-factor-secret-key')
    }

    async getRecoveryCodes() {
        const { data } = await this.axios.get<[]>('/api/account/two-factor-recovery-codes', {});
        return data;
    }

    regenerateRecoveryCodes() {
        return this.axios.post<TwoFactorResponse>('/api/account/two-factor-recovery-codes', {})
    }

}