import { ProgressBar } from 'components/ProgressBar';
import { Accordion } from '@mantine/core';
import { IBuyLimits } from '../types/limits';
import { calculateLimitPercentage, calculateRemainingLimit } from '../utils';
import { PlusCircleIcon } from '@heroicons/react/outline';

interface BuyLimitsProps {
  limits: IBuyLimits;
}

export const BuyLimits = ({ limits }: BuyLimitsProps) => {
  const { bank_card, account_balance, cash } = limits;
  const limitsToRender = [
    {
      header: 'Bank Card',
      limit: bank_card,
    },
    {
      header: 'Account Balance',
      limit: account_balance,
    },
    {
      header: 'Cash',
      limit: cash,
    },
  ];
  return (
    <div>
      <Accordion
        icon={<PlusCircleIcon className="text-tw-gray-400" />}
        classNames={{
          item: 'border-none',
        }}
      >
        {limitsToRender.map((item, index) => (
          <Accordion.Item key={index} label={item.header} iconPosition="right">
            <div>
              <h4 className="my-2 text-gray-darker"> Daily Limit</h4>
              <div className="flex justify-between">
                <p className="my-1 text-xs text-primary">
                  $
                  {calculateRemainingLimit(
                    item.limit.daily_limit,
                    item.limit.daily_sum
                  )}{' '}
                  Remaining
                </p>
                <p className="my-1 text-xs text-gray-darker">
                  ${item.limit.daily_limit} per day
                </p>
              </div>
              <ProgressBar
                progressPercentage={calculateLimitPercentage(
                  item.limit.daily_limit,
                  item.limit.daily_sum
                )}
              />
            </div>
            <div className="my-8">
              <h4 className="my-2 text-gray-darker"> Weekly Limit</h4>
              <div className="flex justify-between">
                <p className="my-1 text-xs text-primary">
                  $
                  {calculateRemainingLimit(
                    item.limit.weekly_limit,
                    item.limit.weekly_sum
                  )}{' '}
                  Remaining
                </p>
                <p className="my-1 text-xs text-gray-darker">
                  ${item.limit.weekly_limit} per week
                </p>
              </div>
              <ProgressBar
                progressPercentage={calculateLimitPercentage(
                  item.limit.weekly_limit,
                  item.limit.weekly_sum
                )}
              />
            </div>
            <div>
              <h4 className="my-2 text-gray-darker"> Monthly Limit</h4>
              <div className="flex justify-between">
                <p className="my-1 text-xs text-primary">
                  {' '}
                  $
                  {calculateRemainingLimit(
                    item.limit.monthly_limit,
                    item.limit.monthly_sum
                  )}{' '}
                  Remaining
                </p>
                <p className="my-1 text-xs text-gray-darker">
                  ${item.limit.monthly_limit} per month
                </p>
              </div>
              <ProgressBar
                progressPercentage={calculateLimitPercentage(
                  item.limit.weekly_limit,
                  item.limit.weekly_sum
                )}
              />
            </div>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};
