import Aside from 'modules/dashboard/components/Aside';
import Header from 'modules/dashboard/components/Header';

import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth, useLogout } from 'modules/auth';
import { useEffect, useState } from 'react';
import Transitions from 'components/Transitions';
import { useSystemInfo } from 'common';
import { useVerify } from 'modules/auth/providers/verification';

export const Dashboard = () => {
  const { user, clearUser } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { update: handleLogout } = useLogout(() => {
    navigate('/auth/login');
    clearUser();
  });

  const { fetchSystemInfo } = useSystemInfo();
  const { getLocationData } = useVerify();

  useEffect(() => {
    fetchSystemInfo();
    getLocationData();
  }, []);

  return (
    <div className="flex min-h-screen">
      <Aside isDrawerOpen={isDrawerOpen} onLogout={handleLogout}  />
      <div className="grow flex bg-gray-bkg">
        {/* Backdrop */}
        <Transitions.FadeIn reveal={isDrawerOpen}>
          <div
            onClick={() => setIsDrawerOpen(false)}
            className="fixed top-0 left-0 z-40 h-screen w-screen bg-[rgba(0,0,0,0.3)]"
          />
        </Transitions.FadeIn>
        <main id="dashboard-container" className="relative grow bg-white mr-2 my-4 md:mx-8 p-4 rounded-xl shadow-sm">
          <Header
            user={user!}
            onOpenDrawer={() => setIsDrawerOpen(true)}
          />
          <Outlet />
        </main>
      </div>
    </div>
  );
};
